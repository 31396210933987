import React from 'react'

const About = () => {
  return (
    <div className='h-[70vh]'>
          <div className='justify-between  items-center  max-w-[1240px] mx-auto px-4 text-black'>

     <h1 className='my-5'>Дорогие покупатели!</h1> 

<h2 className='my-5'>Компания "Miracle of Fragrance" основана в 2016 году.  </h2>

 
 <p>
 За 8 лет успешной работы, мы расширили ассортимент ароматов! Более 500 ароматов лучшего качества! Расширился так же ассортимент флаконов, богатый выбор атомайзеров (флаконы со спреем) и флаконов для масляных запахов: железные, стеклянные, хрустальные, пластиковые и комбинированные. Наша компания смогла удовлетворить потребности как простой розничной точки, так и большого бутика разливной парфюмерии, мы работаем для вас как в рознице, так и в опте. Мы открылись уже в двух городах России, и это только наше начало! Наши адреса: 
• город Ростов-на-Дону, пер. Крепостной 104
• город Ростов-на-Дону, ул. Серафимовича 80
• город Москва, ул. Тихорецкий бульвар 1с6, ТЯК Москва, вход 4 этаж 1
• город Москва, ул. Тихорецкий бульвар 1с6, ТЯК Москва, вход 1, этаж 2, 3 линия, ПР-372 (рядом с кафе «Восточное»)</p>
</div>
</div>
  )
}

export default About
